dmx.Component('google-places-search', {

    initialData:{
        results: [],
        hasMore: false,
        status: ''
    },

    attributes: {
        map: {
            type: String,
            default: null
        },

        fields: { // https://developers.google.com/maps/documentation/javascript/places#place_search_fields
            type: [Array, String],
            default: ['place_id', 'business_status', 'formatted_address', 'geometry', 'icon', 'name', 'type']
        },

        'show-on-map': {
            type: Boolean,
            default: false
        }
    },

    methods: {
        findPlaceFromQuery: function(params) {
            var request = {};
            var fields = params.fields || this.props.fields;
            
            this.clearMarkers();

            request.query = params.query;
            request.fields = Array.isArray(fields) ? fields : fields.split(/\s*,\s*/);

            if (params.bindBounds) {
                request.locationBias = this.map.getBounds();
            } else {
                if (params.latitude && params.longitude) {
                    if (params.radius) {
                        request.locationBias = {
                            center: { lat: +params.latitude, lng: +params.longitude },
                            radius: +params.radius
                        };
                     } else {
                        request.locationBias = { lat: +params.latitude, lng: +params.longitude };
                    }
                }
            }

            var service = new google.maps.places.PlacesService(this.map);
            service.findPlaceFromQuery(request, this.onresults.bind(this));
        },

        findPlaceFromPhoneNumber: function(params) {
            var request = {};
            var fields = params.fields || this.props.fields;

            this.clearMarkers();
            
            request.phoneNumber = params.phoneNumber;
            request.fields = Array.isArray(fields) ? fields : fields.split(/\s*,\s*/);

            if (params.bindBounds) {
                request.locationBias = this.map.getBounds();
            } else {
                if (params.latitude && params.longitude) {
                    if (params.radius) {
                        request.locationBias = {
                            center: { lat: +params.latitude, lng: +params.longitude },
                            radius: +params.radius
                        };
                     } else {
                        request.locationBias = { lat: +params.latitude, lng: +params.longitude };
                    }
                }
            }

            var service = new google.maps.places.PlacesService(this.map);
            service.findPlaceFromPhoneNumber(request, this.onresults.bind(this));
        },

        nearby: function(params) {
            var request = {};

            this.clearMarkers();

            if (params.latitude != null && params.longitude != null) {
                request.location = { lat: +params.latitude, lng: +params.longitude };
                request.radius = +params.radius || 500;
            } else {
                request.bounds = this.map.getBounds();
            }

            if (params.keyword) {
                request.keyword = params.keyword;
            }

            if (params.name) {
                request.name = params.name;
            }

            if (params.openNow) {
                request.openNow = params.openNow;
            }
            
            request.type = params.type;

            var service = new google.maps.places.PlacesService(this.map);
            service.nearbySearch(request, this.onresults.bind(this));
        },

        search: function(params) {
            var request = {};

            this.clearMarkers();

            if (params.latitude != null && params.longitude != null) {
                request.location = { lat: +params.latitude, lng: +params.longitude };
                request.radius = +params.radius || 500;
            } else {
                request.bounds = this.map.getBounds();
            }

            if (params.openNow) {
                request.openNow = params.openNow;
            }

            if (params.type) {
                request.type = params.type;
            }

            request.query = params.query;

            var service = new google.maps.places.PlacesService(this.map);
            service.textSearch(request, this.onresults.bind(this));
        },

        getMore: function() {
            if (this.pagination && this.pagination.hasNextPage) {
                this.pagination.nextPage();
            }
        }
    },

    render: function(node) {
        this.markers = [];
        this.target = document.getElementById(this.props.map);
        this.cluster = this.target && this.target.dmxComponent && this.target.dmxComponent.cluster;
        this.map = this.target && this.target.dmxComponent && this.target.dmxComponent.map;
    },

    update: function(props) {
        if (this.props.map && !this.map) {
            this.cluster = this.target && this.target.dmxComponent && this.target.dmxComponent.cluster;
            this.map = this.target && this.target.dmxComponent && this.target.dmxComponent.map;
        }
    },

    clearMarkers: function() {
        if (this.cluster) {
            this.cluster.clearMarkers();
        }

        this.markers.forEach(function(marker) {
            // remove from map
            marker.setMap(null);
        });

        this.markers = [];
    },

    onresults: function(results, status, pagination) {
        this.set('status', status);
        this.set('hasMore', !!(pagination && pagination.hasNextPage));

        this.pagination = pagination;

        if (status == 'OK') {
            this.set('results', results.map(function(place) {
                return {
                    placeId: place.place_id,
                    address: place.formatted_address,
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng(),
                    icon: place.icon,
                    name: place.name,
                    types: place.types
                }
            }));

            if (this.props['show-on-map']) {
                // create markers on map
                results.forEach(function(place) {
                    var image = {
                        url: place.icon,
                        size: new google.maps.Size(71, 71),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(17, 34),
                        scaledSize: new google.maps.Size(25, 25)
                    };

                    var marker = new google.maps.Marker({
                        //map: this.map,
                        icon: image,
                        title: place.name,
                        position: place.geometry.location
                    });

                    if (this.cluster) {
                        this.cluster.addMarker(marker);
                    } else {
                        marker.setMap(this.map);
                    }

                    this.markers.push(marker);
                }, this);
            }
        } else {
            console.warn('Places search failed.', status);
        }
    }

});
